

.app {
  color: white;
  background-color: black;
  height: 100vh;
  display: grid;
  place-items: center;

  
  
}

.app_videos {
  position: relative;
  height: 92%;
  background-color: black;
  width: 100%;
  max-width: 440px;
 
 
  
}




.logo {
  position: absolute;
  width: 120px;
  height: 120px;
  object-fit: cover;
}



.buttonDownload {
  position: absolute;
  top: 15px;
  right: 10px;
  background-color: #00035d;
  width: 140px;
  height: 50px;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  z-index: 999;
  color: white;

  text-align: center;
 
  display: inline-block;
  font-size: 14px;
  font-weight: bold;

  cursor: pointer;
}



.app_videos::-webkit-scrollbar {
  display: none;
}

.app_videos {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.video {
  position: relative;
  z-index:1;
  height: 88%;
  width: 100%;
  background-color: black;
 
}

.video_player {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: black;
}

.videoSidebar {
  position: absolute;
  top: 40%;
  right: 20px;
 
}

.videoSidebar__button{
  margin-bottom: 30px;
}

.videoFooter {
  position: relative;
  bottom: 90px;
  color: white;
  left: 10px;
  max-width: 350px;
  max-height: 50px;
  
  
 

}

.videoFooter__user {
 

  align-items: center;
  display: grid;
  max-width: 250px;
  max-height: 50px;
  
}


.videoFooter__profile {
  object-fit: cover;
  position: absolute;
  height: 50px;
  width: 50px;
  bottom: -10px;
  background-color: lightgray;
 
  border-radius: 8px;
}

.videoFooter__image {
  object-fit: cover;
  position: absolute;
  height: 50px;
  width: 50px;
  bottom: -10px;
  background-color: lightgray;
 
  border-radius: 8px;
}

.videoFooter_text {
  margin-left: 60px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

 
  
}

h3 {
  font-size: 15px;
  
}

p {
  font-size: 15px;
  text-overflow: ellipsis;
  font-weight: bold;
  
}


.videoFooter_description {
 

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  position: absolute;
  bottom: 5px;
  left: 10px;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
}


.location {
  position: absolute;
  bottom: 30px;
  height: 18px;
  left: 10px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}


@media (max-width:450px) {

  .app_videos {
    position: relative;
    height: 82%;
    background-color: black;
    width: 100%;
    max-width: 340px;
   
   
    
  }
}



/* @media (max-width:330px) {


  .app {
    color: white;
    background-color: black;
    height: 100%;
 
    display: grid;
    place-items: center;
    
    
  }


  .app_videos {
    position: relative;
    height: 100%;
    background-color: black;
    width: 100%;
    max-width: 280px;
    
   
    
  }
  
  .videoFooter_description {
 
    max-width: 200px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
   
 
    
  }

  .description {
    position: absolute;
    bottom: 5px;
    left: 10px;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    
    
  }

  .videoFooter_text {
    margin-left: 60px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  
   
    
  }


  
  .location {
    position: absolute;
    bottom: 30px;
    width: 150px;
    height: 14px;
    left: 10px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    
  
  }

}




@media (max-height:750px) {


  .app {
    color: white;
    background-color: black;
    height: 90vh;
    height: 80%;
    display: grid;
    place-items: center;
  
  
    
    
  }


  .app_videos {
    position: relative;
    height: 100%;
    background-color: black;
    width: 100%;
    max-width: 280px;
    
   
    
  }
  
  .videoFooter_description {
 
    max-width: 200px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
   
 
    
  }

  .description {
    position: absolute;
    bottom: 5px;
    left: 10px;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    
    
  }

  .videoFooter_text {
    margin-left: 60px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  
   
    
  }


  
  .location {
    position: absolute;
    bottom: 30px;
    width: 150px;
    height: 14px;
    left: 10px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    
  
  }

  .trip {
  
    width:"250px";
    height:"153px";

   
    position:"relative";
}

}




@media (max-width:280px) {

  .app {
    color: white;
    background-color: black;
    height: 120vh;
    display: grid;
    place-items: center;
    
    
  }


  .app_videos {
    position: relative;
    height: 100%;
    background-color: black;
    width: 100%;
    max-width: 250px;
   
    
  }
  
  .videoFooter_description {
 
    position: absolute;
    bottom: 5px;
    left: 10px;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
   
 
    
  }

  .description {
    position: absolute;
    bottom: 5px;
    left: 10px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    
  }

  .videoFooter_text {
    margin-left: 60px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  
   
    
  }



  
  .location {
    position: absolute;
    bottom: 30px;
    width: 150px;
    height: 14px;
    left: 10px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    
  
  }

} */


 /* @media (min-width:630px) {




  .app_videos {
    position: absolute;
    z-index:1;
    height: 100%;
    width: 100%;
    background-color: black;
    display: grid;
    place-items: center;
    
   
    
  }



  .video {
    position: relative;
    z-index:1;
    height: 100%;
    width: 100%;
    background-color: black;
  
  }

  .videoFooter_description {
 
    position: absolute;
    bottom: 5px;
    left: 10px;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    
    
  }

  .description {
    position: absolute;
    bottom: 5px;
    left: 10px;
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    
  }


  
  .location {
    position: absolute;
    bottom: 30px;
    width: 150px;
    height: 14px;
    left: 10px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    
  
  }
  

}   */
